<template>
    <footer class="mt-4">
        <div class="container p-0">
            <div class="d-flex justify-content-between align-items-start flex-wrap">
                <div class="col-lg-3 d-flex">
                    <div class="col-6">
                        <h6 class="text-success text-uppercase mb-2">Fornitori</h6>
                        <a href="https://www.bresciamobilita.it/fornitori/bandi-di-gara" class="my-a text-secondary d-block">
                            <small>Bandi e Gare</small>
                        </a>
                        <a href="https://www.bresciamobilita.it" class="my-a text-secondary">
                            <small>Accredito Fornitori</small>
                        </a>
                    </div>
                    <div class="col-6">
                        <h6 class="group text-uppercase mb-2">Il Gruppo</h6>
                        <a href="https://www.bresciamobilita.it/il-gruppo/brescia-mobilita" class="my-a text-secondary d-block">
                            <small>Brescia Mobilità</small>
                        </a>
                        <a href="https://www.bresciamobilita.it/il-gruppo/brescia-trasporti" class="my-a text-secondary d-block">
                            <small>Brescia Trasporti</small>
                        </a>
                        <a href="https://www.bresciamobilita.it/il-gruppo/metro-brescia" class="my-a text-secondary d-block">
                            <small>Metro Brescia</small>
                        </a>
                        <a href="https://trasparenza.bresciamobilita.it/" class="my-a text-secondary d-block">
                            <small>Società Trasparente</small>
                        </a>
                        <a href="https://www.bresciamobilita.it/il-gruppo/privacy" class="my-a text-secondary d-block">
                            <small>Privacy</small>
                        </a>
                        <a href="https://www.bresciamobilita.it/amministrazione-digitalea" class="my-a text-secondary d-block">
                            <small>Amministrazione Digitale</small>
                        </a>
                        <a href="https://www.bresciamobilita.it/wifi" class="my-a text-secondary">
                            <small>WiFi</small>
                        </a>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="d-flex align-items-center">
                        <img src="https://brescia-site-bresciamobilita.netdna-ssl.com/assets/img/logo-mini.png">
                        <h6 class="ms-4">Brescia Mobilità S.p.A. - Società Metropolitana di Mobilità</h6>
                    </div>
                    <p class="text-secondary">
                        <br>
                        25135 Brescia Via L. Magnolini, 3
                        <br>
                        <strong>Fax</strong>
                        030 30 61 004
                        <strong>Customer Care</strong>
                        030 30 61 200
                        <strong>
                            Mail info</strong>
                        <a href="mailto: customercare@bresciamobilita.it">
                            customercare@bresciamobilita.it
                        </a><br>
                        <strong>Mail segnalazioni/reclami</strong>
                        <a href="mailto: segnalazioni@bresciamobilita.it">
                            segnalazioni@bresciamobilita.it
                        </a>
                        <strong>PEC</strong>
                        <a href="mailto: bresciamobilita@legalmail.it">
                            bresciamobilita@legalmail.it
                        </a><br>
                        Capitale Sociale € 52.000.000,00 i.v. Registro Imprese di Brescia Numero REA BS-433626 Codice Fiscale 02246660985 Società soggetta a direzione e coordinamento da parte del Comune di Brescia
                    </p>
                    <div class="social">
                        <a href="https://www.facebook.com/bresciamobilita" target="_blank">
                            <img src="https://brescia-site-bresciamobilita.netdna-ssl.com/assets/img/facebook-blue.png">
                        </a>
                        <a href="https://twitter.com/bresciamobilita" target="_blank">
                            <img src="https://brescia-site-bresciamobilita.netdna-ssl.com/assets/img/twitter-blue.png">
                        </a>
                        <a href="https://www.youtube.com/user/BresciaMobilita/" target="_blank">
                            <img src="https://brescia-site-bresciamobilita.netdna-ssl.com/assets/img/youtube-blue.png">
                        </a>
                        <a href="mailto: customercare@bresciamobilita.it">
                            <img src="https://brescia-site-bresciamobilita.netdna-ssl.com/assets/img/mail-blue.png">
                        </a>
                    </div>
                </div>
                <div class="col-lg-3">
                    <h6>Per ulteriori informazioni:</h6>
                    <p class="text-secondary">
                        <br>Customer Care Brescia Mobilità<br>
                        Tel. 030 3061200<br>
                        attivo dal lunedì al sabato dalle ore 8.00 alle ore 17.00<br><br>
                        <a href="mailto:customercare@bresciamobilita.it" target="_blank" class="d-block">customercare@bresciamobilita.it</a>
                        <a href="https://www.facebook.com/bresciamobilita" target="_blank" class="d-block">https://www.facebook.com/bresciamobilita</a>
                        <a href="https://www.facebook.com/metrobrescia" target="_blank" class="d-block">https://www.facebook.com/metrobrescia</a>
                        <a href="https://twitter.com/bresciamobilita" target="_blank" class="d-block">https://twitter.com/bresciamobilita</a>
                        <a href="https://twitter.com/metro_brescia" target="_blank" class="d-block">https://twitter.com/metro_brescia</a>
                    </p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    footer {
        padding: 20px;
        background-color: #e2e2e2;

        h6 {
            font-size: 14px;
        }

        p,
        small {
            font-size: 12px;
        }

        .group {
            color: #f7911d;
        }

        .social {
            a {
                margin: 0 10px;
            }
            img {
                width: 26px;
                height: 26px;
            }
        }
    }

    @media screen and (max-width: 992px) {
        
        .col-lg-3{
            display: none !important;
        }
    }
</style>