<template>
    <div class="container p-0">
        <BresciaHeader/>
            <div class="d-flex justify-content-center flex-wrap">
                <div class="col-12 col-lg-6 col-xl-4 bg-brand">
                    <Login v-if="this.$route.name == 'Login'"/>
                    <Sign v-if="this.$route.name == 'Sign'"/>
                    <EmailService v-if="this.$route.name == 'EmailService'"/>
                    <ResetPassword v-if="this.$route.name == 'ResetPassword'"/>
                </div>
            </div>
        <BresciaFooter/>
    </div>
</template>

<script>
import BresciaHeader from '../Header.vue'
import Login from '@/components/Login.vue'
import Sign from '@/components/Sign.vue'
import EmailService from '@/components/EmailService.vue'
import ResetPassword from '@/components/ResetPassword.vue'
import BresciaFooter from '../Footer.vue'
export default {
    name: 'AMTAuth',
    components: {
        BresciaHeader,
        Login,
        Sign,
        EmailService,
        ResetPassword,
        BresciaFooter
    }
}
</script>

<style lang="scss" scoped>
    .bg-brand {
        background-color: #3A9AB3;
    }
</style>