<template>
    <header class="mb-4 px-2">
        <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="col-md-4">
                <a href="https://www.bresciamobilita.it/">
                    <img src="https://brescia-site-bresciamobilita.netdna-ssl.com/assets/img/logo.jpg" class="logo">
                </a>
            </div>
            <div class="col-md-4 d-flex justify-content-between align-items-center icon-container">
                <a href="https://www.bresciamobilita.it/map#/mode=busRoutes/routes" class="my-a">
                    <div class="icon d-flex flex-column align-items-center">
                        <img src="../../../assets/brescia-ico/trasporto.png" class="img-icon" alt="ico trasporto">
                        <img src="../../../assets/brescia-ico/trasporto-hov.png" class="icon-hov" alt="ico trasporto">
                        <small>TRASPORTO PUBBLICO</small>
                    </div>
                </a>
                <a href="https://www.bresciamobilita.it/map#/mode=busRoutes/poitypes=ztlarea,ztlentrance" class="my-a">
                    <div class="icon d-flex flex-column align-items-center">
                        <img src="../../../assets/brescia-ico/ztl.png" alt="ico ztl" class="img-icon">
                        <img src="../../../assets/brescia-ico/ztl-hov.png" class="icon-hov" alt="ico ztl">
                        <small>ZTL</small>
                    </div>
                </a>
                <a href="https://www.bresciamobilita.it/map#/mode=busRoutes/poitypes=parking" class="my-a">
                    <div class="icon d-flex flex-column align-items-center">
                        <img src="../../../assets/brescia-ico/parcheggio.png" alt="ico parcheggio" class="img-icon">
                        <img src="../../../assets/brescia-ico/parcheggio-hov.png" class="icon-hov" alt="ico parcheggio">
                        <small>PARCHEGGI</small>
                    </div>
                </a>
                <a href="https://www.bresciamobilita.it/map#/mode=busRoutes/poitypes=bicimia" class="my-a">
                    <div class="icon d-flex flex-column align-items-center">
                        <img src="../../../assets/brescia-ico/bici.png" alt="ico bici" class="img-icon">
                        <img src="../../../assets/brescia-ico/bici-hov.png" class="icon-hov" alt="ico bici">
                        <small>BICI MIA</small>
                    </div>
                </a>
                <a href="https://www.bresciamobilita.it/map#/mode=calculatePath" class="my-a">
                    <div class="icon d-flex flex-column align-items-center">
                        <img src="../../../assets/brescia-ico/percorso.png" alt="ico percorso" class="img-icon">
                        <img src="../../../assets/brescia-ico/percorso-hov.png" class="icon-hov" alt="ico percorso">
                        <small>CALCOLA PERCORSO</small>
                    </div>
                </a>
            </div>
            <div class="social col-md-4 d-flex justify-content-end">
                <a href="https://www.facebook.com/bresciamobilita" target="_blank">
                    <img src="https://brescia-site-bresciamobilita.netdna-ssl.com/assets/img/facebook.png">
                </a>
                <a href="https://twitter.com/bresciamobilita" target="_blank" class="mx-2">
                    <img src="https://brescia-site-bresciamobilita.netdna-ssl.com/assets/img/twitter.png">
                </a>
                <a href="https://www.youtube.com/user/BresciaMobilita/" target="_blank">
                    <img src="https://brescia-site-bresciamobilita.netdna-ssl.com/assets/img/youtube.png">
                </a>
            </div>
        </div>
        <nav>
            <div class="d-flex justify-content-between align-items-center">
                <div class="dropdown">
                    <a href="https://www.bresciamobilita.it/" class="my-a text-secondary"><strong>HOME</strong></a>
                </div>
                <div class="col-xxl-8 d-flex align-items-center ms-4">
                    <img src="https://brescia-site-bresciamobilita.netdna-ssl.com/assets/img/icon-blue.png">

                    <div class="dropdown">
                        <a href="https://www.bresciamobilita.it/" class="my-a drop-a ms-2"><strong>AUTOBUS</strong></a>
                        <div class="dropdown-menu">
                            <div class="col-3">
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/il-servizio">
                                    <span>Il Servizio</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/orari">
                                    <span>Orari</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/mappe-linee">
                                    <span>Mappe</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/titoli-di-viaggio">
                                    <span>Titoli di viaggio</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/servizi-scolastici">
                                    <span>Servizi scolastici</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/noleggio-granturismo">
                                    <span>Noleggio granturismo</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/condizioni-di-viaggio">
                                    <span>Condizioni di viaggio</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/diversamente-abili">
                                    <span>Diversamente abili</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://eshop.bresciamobilita.it/">
                                    <span>Shop-online</span>
                                </a>
                            </div>
                            <div class="col-3">
                                <a href="https://www.bresciamobilita.it/orari">
                                    <img class="w-100" src="https://www.bresciamobilita.it/public/bm/menu/AUTOBUS-METRO-orari.jpg">
                                </a>
                            </div>
                            <div class="col-3">
                                <a href="https://www.bresciamobilita.it/titoli-di-viaggio">
                                    <img class="w-100" src="https://www.bresciamobilita.it/public/bm/menu/AUTOBUS-METRO-titoliviaggio.jpg">
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="dropdown">
                        <a href="https://www.bresciamobilita.it/" class="my-a drop-a ms-4"><strong>METRO</strong></a>
                        <div class="dropdown-menu">
                            <div class="col-3">
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/la-metropolitana-di-brescia">
                                    <span>La Metropolitana di Brescia</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/mappe-linee">
                                    <span>Frequenze</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/titoli-di-viaggio">
                                    <span>Titoli di viaggio</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/condizioni-di-viaggio">
                                    <span>Condizioni di viaggio</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://eshop.bresciamobilita.it/">
                                    <span>Shop-online</span>
                                </a>
                            </div>
                            <div class="col-3">
                                <a href="https://www.bresciamobilita.it/la-metropolitana-di-brescia">
                                    <img class="w-100" src="https://www.bresciamobilita.it/public/bm/menu/METRO-metro.jpg">
                                </a>
                            </div>
                            <div class="col-3">
                                <a href="https://www.bresciamobilita.it/titoli-di-viaggio">
                                    <img class="w-100" src="https://www.bresciamobilita.it/public/bm/menu/AUTOBUS-METRO-titoliviaggio.jpg">
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="dropdown">
                        <a href="https://www.bresciamobilita.it/" class="my-a drop-a ms-4"><strong>SHARING</strong></a>
                        <div class="dropdown-menu">
                            <div class="col-3">
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/green/bicimia-il-bike-sharing-di-brescia">
                                    <span>BiciMia</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/green/bicinamia">
                                    <span>Bicina Mia</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/green/bikepoint">
                                    <span>Bike Point</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/green/bike-station">
                                    <span>Bike Station</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/green/automia">
                                    <span>AutoMiae</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/green/e-sharing">
                                    <span>E-sharing</span>
                                </a>
                            </div>
                            <div class="col-3">
                                <a href="https://www.bresciamobilita.it/green/bicimia-il-bike-sharing-di-brescia">
                                    <img class="w-100" src="https://www.bresciamobilita.it/public/bm/menu/GREEN-bicimia-new.jpg">
                                </a>
                            </div>
                            <div class="col-3">
                                <a href="https://www.bresciamobilita.it/green/bikepoint">
                                    <img class="w-100" src="https://www.bresciamobilita.it/public/bm/menu/GREEN-bikepoint.jpg">
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="dropdown">
                        <a href="https://www.bresciamobilita.it/" class="my-a drop-a ms-4"><strong>PARKING</strong></a>
                        <div class="dropdown-menu">
                            <div class="col-3">
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/parking/parcheggi-in-struttura">
                                    <span>Parcheggi in Struttura</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/parking/parcometri">
                                    <span>Parcometri</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/green/bike-station">
                                    <span>Bike Station</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/parking/parcheggi-e-parcometri-di-desenzano-del-garda">
                                    <span>Parcheggi e parcometri di Desenzano d/G</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/parking/parcometri-iseo">
                                    <span>Parcometri Iseo</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/parking/parcometri-castenedolo">
                                    <span>Parcometri Castenedolo</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/parking/parcometri-passirano-1">
                                    <span>Parcometri Passirano</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/parking/pagamenti-digitali">
                                    <span>Pagamenti Digitali</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://eshop.bresciamobilita.it">
                                    <span>Shop-online</span>
                                </a>
                            </div>
                            <div class="col-3">
                                <a href="https://www.bresciamobilita.it/parking/parcheggi-in-struttura">
                                    <img class="w-100" src="https://www.bresciamobilita.it/public/bm/menu/PARCHEGGI-parkinstruttura.jpg">
                                </a>
                            </div>
                            <div class="col-3">
                                <a href="https://www.bresciamobilita.it/parking/parcometri">
                                    <img class="w-100" src="https://www.bresciamobilita.it/public/bm/menu/PARCHEGGI-parcometri.jpg">
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="dropdown">
                        <a href="https://www.bresciamobilita.it/" class="my-a drop-a ms-4"><strong>ZTL</strong></a>
                        <div class="dropdown-menu">
                            <div class="col-3">
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/ztl/ztl-di-brescia">
                                    <span>Ztl di Brescia</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/ztl/chi-puo-entrare">
                                    <span>Chi può entrare</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/ztl/gestori-convenzionati">
                                    <span>Gestori convenzionati</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/map#/mode=busRoutes/poitypes=ztlarea,ztlentrance">
                                    <span>Mappa</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/ztl/permessi">
                                    <span>Permessi</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/ztl/gratta-e-sosta">
                                    <span>Gratta e Sosta</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/ztl/sanzioni">
                                    <span>Sanzioni</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/ztl/contatti">
                                    <span>Contatti</span>
                                </a>
                            </div>
                            <div class="col-3">
                                <a href="https://www.bresciamobilita.it/ztl/gratta-e-sosta">
                                    <img class="w-100" src="https://www.bresciamobilita.it/public/bm/menu/ZTL-grattaesosta.jpg">
                                </a>
                            </div>
                            <div class="col-3">
                                <a href="https://www.bresciamobilita.it/map#/mode=busRoutes/poitypes=ztlentrance,ztlarea/">
                                    <img class="w-100" src="https://www.bresciamobilita.it/public/bm/menu/ZTL-areeztl.jpg">
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="dropdown">
                        <a href="https://www.bresciamobilita.it/" class="my-a drop-a ms-4"><strong>ALTRI SERVIZI</strong></a>
                        <div class="dropdown-menu">
                            <div class="col-3">
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/altri-servizi/city-logistic">
                                    <span>City Logistic</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/altri-servizi/officina-per-privati">
                                    <span>Officina per privati</span>
                                </a>
                            </div>
                            <div class="col-3">
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/altri-servizi/gestione-semafori">
                                    <span>Gestione Semafori</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/altri-servizi/impianti-elettrici-e-fotovoltaici">
                                    <span>Impianti elettrici e Fotovoltaici</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/altri-servizi/wi-fi">
                                    <span>Wi-Fi</span>
                                </a>
                            </div>
                            <div class="col-3"></div>
                        </div>
                    </div>
                    <div class="dropdown">
                        <a href="https://www.bresciamobilita.it/" class="my-a drop-a not-drop-a ms-4"><strong>NEWS</strong></a>
                    </div>
                    <div class="dropdown">
                        <a href="https://www.bresciamobilita.it/" class="my-a drop-a ms-4"><strong>PUNTI DI CONTATTO</strong></a>
                        <div class="dropdown-menu">
                            <div class="col-3">
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/punti-di-contatto/customer-care">
                                    <span>Customer Care</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/punti-di-contatto/infopoint">
                                    <span>Infopoint</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/punti-di-contatto/punti-vendita">
                                    <span>Punti Vendita</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/reclami">
                                    <span>Reclami</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/punti-di-contatto/pubblicita">
                                    <span>Pubblicità</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item" href="https://www.bresciamobilita.it/punti-di-contatto/feedback-per-laccessibilita">
                                    <span>Feedback per l'accessibilità</span>
                                </a>
                            </div>
                            <div class="col-3">
                                <a href="https://www.bresciamobilita.it/punti-di-contatto/customer-care">
                                    <img class="w-100" src="https://www.bresciamobilita.it/public/bm/menu/PUNTICONTATTO-customercare.jpg">
                                </a>
                            </div>
                            <div class="col-3">
                                <a href="https://www.bresciamobilita.it/punti-di-contatto/infopoint">
                                    <img class="w-100" src="https://www.bresciamobilita.it/public/bm/menu/PUNTICONTATTO-infopoint-new2.jpg">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xxl-2 d-flex align-items-center">
                    <img src="https://brescia-site-bresciamobilita.netdna-ssl.com/assets/img/icon-green.png">
                    <div class="dropdown">
                        <a href="https://www.bresciamobilita.it/" class="my-a drop-a drop-a-success text-success ms-2">FORNITORI</a>
                        <div class="dropdown-menu bg-success">
                            <div class="col-3">
                                <a class="my-a text-uppercase dropdown-item dropdown-item-success" href="https://www.bresciamobilita.it/fornitori/bandi-di-gara">
                                    <span>Bandi di Gara</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item dropdown-item-success" href="https://www.bresciamobilita.it/fornitori/avvisi-di-gara">
                                    <span>Avvisi di Gara</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item dropdown-item-success" href="https://www.bresciamobilita.it/fornitori/sistema-di-qualificazione">
                                    <span>Sistemi di Qualificazione</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item dropdown-item-success" href="https://www.bresciamobilita.it/fornitori/esiti-di-gara">
                                    <span>Esiti di Gara</span>
                                </a>
                            </div>
                            <div class="col-3">
                                <a class="my-a text-uppercase dropdown-item dropdown-item-success" href="https://www.bresciamobilita.it/fornitori/accredito-fornitori">
                                    <span>Accredito Fornitori</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item dropdown-item-success" href="https://www.bresciamobilita.it/fornitori/dati-di-fatturazione">
                                    <span>Dati di Fatturazione</span>
                                </a>
                            </div>
                            <div class="col-3"></div>
                        </div>
                    </div>
                    <div class="dropdown">
                        <a href="https://www.bresciamobilita.it/fornitori/cercalavoro" class="my-a ms-2 text-success">CERCA LAVORO</a>
                    </div>
                </div>
                <div class="col-xxl-1 d-flex align-items-center ms-4">
                    <img src="https://brescia-site-bresciamobilita.netdna-ssl.com/assets/img/icon-orange.png">
                    
                    <div class="dropdown">
                        <a href="https://www.bresciamobilita.it/" class="my-a drop-a group ms-2">IL GRUPPO</a>
                        <div class="dropdown-menu dropdown-menu-group">
                            <div class="col-3">
                                <a class="my-a text-uppercase dropdown-item dropdown-item-group" href="https://www.bresciamobilita.it/il-gruppo/brescia-mobilita">
                                    <span>Brescia Mobilità</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item dropdown-item-group" href="https://www.bresciamobilita.it/il-gruppo/brescia-trasporti">
                                    <span>Brescia Trasporti</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item dropdown-item-group" href="https://www.bresciamobilita.it/il-gruppo/metro-brescia">
                                    <span>Metro Brescia</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item dropdown-item-group" href="https://www.bresciamobilita.it/il-gruppo/omb-international">
                                    <span>Omb International</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item dropdown-item-group" href="https://www.bresciamobilita.it/il-gruppo/carta-della-mobilita">
                                    <span>Carta della Mobilità</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item dropdown-item-group" href="https://www.bresciamobilita.it/il-gruppo/bilancio-sociale">
                                    <span>Bilancio di Sostenibilità</span>
                                </a>
                                <a class="my-a text-uppercase dropdown-item dropdown-item-group" href="https://www.bresciamobilita.it/il-gruppo/politica-aziendale-del-gruppo">
                                    <span>Politica Aziendale del Gruppo</span>
                                </a>
                            </div>
                            <div class="col-6">
                                <a href="https://trasparenza.bresciamobilita.it/">
                                    <img class="w-100" src="https://www.bresciamobilita.it/public/bm/menu/GRUPPO-societatrasparente.jpg">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
    
    .logo {
        width: 40%;
    }

    .icon  {
        .img-icon,
        .icon-hov {
            width: 60px;
            height: 60px;
        }
        .icon-hov {
            display: none;
        }
        small {
            margin: 0 -60px -28px -60px;
            display: none;
            margin-top: 10px;
            color: #008fd1;
            font-weight: 700;
            font-size: 12px;
        }

        &:hover {
            .img-icon {
                display: none;
            }
            .icon-hov {
                display: block;
            }
            small {
                display: block;
            }
        }
    }
    .social {
        img {
            width: 30px;
            height: 30px;
        }
    }

    nav {
        position: relative;
        padding: 8px 20px;
        background-color: #e2e2e2;

        .dropdown{
            position: static;

            .drop-a {
                position: relative;
                color: #008fd1;
            }

            .dropdown-menu {
                min-width: 1304px;
                justify-content: space-around;
                align-items: flex-start;
                top: 45px;
                left: 0;
                padding: 20px 0;
                border: 0;
                border-radius: 0;
                background-color: #008fd1;

                .dropdown-item {
                    color: white;

                    &:not(&:last-child) {
                        border-bottom: 1px solid white;
                    }

                    &:hover {
                        color: #008fd1;
                        background-color: rgba(white, 0.5);
                    }
                }

                .dropdown-item-success {
                    &:hover {
                        color: rgba(var(--bs-success-rgb)) !important;
                    }
                }

                .dropdown-item-group {
                    &:hover {
                        color: #f7911d !important;
                    }
                }
            }

            .dropdown-menu-group {
                background-color: #f7911d !important;
            }

            &:hover {
                cursor: pointer;

                .dropdown-menu {
                    display: flex;
                    margin-top: 0;
                }

                .drop-a {
                    color: #b7b7b7;

                    &::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 25%;
                        transform: translate(-25%, 50%);
                        border-style: solid;
                        border-width: 0 25px 25px;
                        border-color: #008fd1 transparent;
                        display: block;
                        width: 0;
                        z-index: 1;
                    }
                }

                .not-drop-a::after {
                    display: none !important;
                }

                .drop-a-success::after {
                    border-color: rgba(var(--bs-success-rgb)) transparent;
                }

                .group::after {
                    border-color: #f7911d transparent;
                }
            }
        }

        a {
            font-size: 14px;
        }

        .group {
            color: #f7911d !important;
        }
    }
    @media screen and (max-width: 1399px) {
        
        nav {

            strong {
                font-size: 12px;
            }

            .dropdown {
                .dropdown-menu {
                    min-width: 1124px;
                }
            }
        }
    }
    @media screen and (max-width: 1199px) {
        
        nav {

            strong {
                font-size: 10px;
            }

            .dropdown {
                .dropdown-menu {
                    min-width: 944px;
                    top: 60px;
                }
            }
        }
    }
    @media screen and (max-width: 992px) {
        
        nav,
        .icon-container {
            display: none !important;
        }
    }
</style>